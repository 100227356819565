import React from "react";

import { IPage } from "interfaces/page.interface";
import { ContactRequestService } from "services/static/contactrequest.service";
import { ContactRequestDTO } from "dto/static/contactrequest.dto";
import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";
import { FormContactRequest } from "components/static/contactrequest/FormContactRequest";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Grid, Box } from "@mui/material";
import { DetailGrid } from "components/elements/detail/DetailGrid";

const service = new ContactRequestService();
const EditContactRequest: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, detailUrl, object, loading, setLoading, id } =
    useEditObject<ContactRequestDTO>(mainObject, currentRoute, get);

  const onSubmit = (obj: ContactRequestDTO) => {
    if (!obj) return;
    setLoading(true);
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
      />

      <DataDisplayBox>
        <Grid>
          <DetailGrid
            object={object}
            fields={CommonTools.parseDetailFields(
              ["name", "date", "phone", "email", "typeDepartment", "message"],
              ContactRequestDTO.getDetailFields()
            )}
          />
        </Grid>
        <Box mt={3}>
          <FormContactRequest
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { EditContactRequest };
