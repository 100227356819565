import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class ContactRequestDTO implements Idto {
  id?: number | string;
  date?: number;

  idtypedepartment?: number;

  status?: number;
  name?: string;
  reference?: string;
  phone?: string;

  email?: string;
  message?: string;
  response?: string;

  constructor(
    id?: number | string,
    status?: number,
    name?: string,
    reference?: string,
    phone?: string,
    email?: string,
    message?: string,
    response?: string,
    date?: number,
    idtypedepartment?: number
  ) {
    this.id = id || 0;
    this.status = status || Status.UNPROCESSED;
    this.name = name || "";
    this.reference = reference || "";
    this.phone = phone || "";
    this.email = email || "";
    this.message = message || "";
    this.response = response || "";
    this.date = date || 0;
    this.idtypedepartment = idtypedepartment || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "reference",
        value: ["reference"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "date",
        value: ["date_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "phone",
        value: ["phone"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "email",
        value: ["email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typeDepartment",
        value: ["typeDepartmentObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "message",
        value: ["message"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "response",
        value: ["response"],
      },
    ];
  }

  static checkStatus(obj: ContactRequestDTO): boolean {
    if (!obj) return false;
    if (obj.status !== Status.PROCESSED) return true;
    else return false;
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL, "date"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_StandardColumn(LL, "phone"),
      ComponentCommonTools.columns_StandardColumn(LL, "email"),
      ComponentCommonTools.columns_StandardColumn(LL, "reference"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType,
        ContactRequestDTO.checkStatus
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(
    data: Array<ContactRequestDTO>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["name"])
        )
      );
    });
    return result;
  }
}
