import { Grid } from "@mui/material";

import { Loading } from "components/elements/loading/Loading";

import { ContactRequestDTO } from "dto/static/contactrequest.dto";
import { useDetailObject } from "hooks/useDetailObject";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { ContactRequestService } from "services/static/contactrequest.service";
import { DetailGrid } from "components/elements/detail/DetailGrid";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Status } from "tools/types/status";


const service = new ContactRequestService();
const DetailContactRequest: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, editUrl, listUrl, object, loading } =
    useDetailObject<ContactRequestDTO>(mainObject, currentRoute, get);

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;
  if (!object.id) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
        showEdit={object.status !== Status.PROCESSED}
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid object={object} fields={ContactRequestDTO.getDetailFields()} />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { DetailContactRequest };
