import { Box, Typography } from "@mui/material";

import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { MyTextField } from "components/elements/form/MyTextField";


import { ContactRequestDTO } from "dto/static/contactrequest.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { Status } from "tools/types/status";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: ContactRequestDTO;
  onSubmit: (data: ContactRequestDTO) => void;
  loading: boolean;
};
const FormContactRequest: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField,,,,vResults] = useForm<ContactRequestDTO>(
    defaultObject,
    RequiredValidator.getValidators(["response"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    obj.status = Status.PROCESSED;
    onSubmit(obj);
  };
  if (obj.status === Status.PROCESSED) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Typography variant="h6" align="center">
          {LL("already_processed")}
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="response"
            label={LL("field_response")}
            setObjectField={setObjField}
            value={obj.response}
            multiline
            minRows={3}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormContactRequest };
