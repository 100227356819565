import {
    ServerCategoryBlockSelect,
  } from "components/elements/select/ServerCategoryBlockSelect";
  import RequestListDTO from "dto/app/requestlist.dto";
  import { CategoryServiceDto } from "dto/static/categoryservice.dto";
  import React from "react";
  import { CategoryServiceService } from "services/static/categoryservice.service";
  
  
  type Props = {
    field?: string;
    value?: string;
    setObjectField: (field: string, value: any) => void;
    label?: string;
  };
  const service = new CategoryServiceService();
  const SelectCategoryService: React.FC<Props> = ({
    field = "idcategory",
    value,
    setObjectField,
    label = "category",
  }) => {
    const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
      service.getList(cb, cbParams, data);
    };
  
    return (
      <ServerCategoryBlockSelect
        setObjectField={setObjectField}
        value={value}
        getList={getList}
        processOptions={CategoryServiceDto.parseToSelectOptions}
        field={field}
        label={label}
      />
    );
  };
  
  export { SelectCategoryService };
  