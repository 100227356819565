import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useLabel } from "hooks/useLabel";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleConfirmDelete: () => void;
  handleCancelDelete: () => void;
  showField?: string;
  _obj: any;
};

const MessagesContainerPopUpConfirmDelete: React.FC<Props> = ({
  open,
  setOpen,
  handleConfirmDelete,
  handleCancelDelete,
  showField,
  _obj,
}) => {
  const { LL } = useLabel();
  const [obj, setObj] = useState<any>();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (_obj === undefined) {
      setOpen(false);
      return;
    }
    setObj(_obj);
  }, [_obj]);

  useEffect(() => {
    if (obj === undefined) {
      setOpen(false);
      return;
    }
    handleSetMessage();
  }, [obj]);

  const handleClose = () => {
    if (handleCancelDelete === undefined) {
      setOpen(false);
      return;
    }
    setObj(undefined);
    handleCancelDelete();
    setMessage("");
  };

  const confirmDelete = () => {
    if (handleConfirmDelete === undefined) {
      setOpen(false);
      return;
    }
    handleConfirmDelete();
    setMessage("");
  };

  const handleSetMessage = () => {
    if (obj === undefined) setOpen(false);
    if (showField === undefined) {
      setOpen(false);
      return;
    }
    if (obj[showField] === undefined) {
      setMessage(LL("object"));
      return;
    }

    setMessage(obj[showField]);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{LL("Delete_confirm")}</DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          {LL("Do you want to delete this object: ")}
        </Typography>
        <DialogContentText id="alert-dialog-description" sx={{
          textAlign: "center",
        }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmDelete} color="secondary">
          {LL("Delete")}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {LL("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { MessagesContainerPopUpConfirmDelete };
