import React, { useState } from "react";
import { BenefitService } from "services/static/benefit.service";

import { IPage } from "interfaces/page.interface";
import { useGetUrls } from "hooks/useGetUrls";
import { BenefitDTO } from "dto/static/benefit.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";
import { HeaderAdd } from "components/elements/add/HeaderAdd";
import { FormBenefit } from "components/static/benefit/FormBenefit";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useLanguage } from "providers/LanguageProvider";

const service = new BenefitService();
const AddBenefit: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject, currentRoute);
  const [loading, setLoading] = useState<boolean>(false);
  const { idSelectedLanguage } = useLanguage();
  const onSubmit = (obj: BenefitDTO) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["_idlang"]),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
        <FormBenefit
          defaultObject={new BenefitDTO(idSelectedLanguage)}
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { AddBenefit };
