import React from "react";
import { IPage } from "interfaces/page.interface";
import { TypeDepartmentService } from "services/nomenclature/typedepartment.service";
import { NomenclatureList } from "components/elements/nomenclature/NomenclatureList";
import { NomenclatureDetail } from "components/elements/nomenclature/NomenclatureDetail";
import { NomenclatureAdd } from "components/elements/nomenclature/NomenclatureAdd";
import { NomenclatureEdit } from "components/elements/nomenclature/NomenclatureEdit";

const service = new TypeDepartmentService();

const TypeDepartment: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureList
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const DetailTypeDepartment: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  return (
    <NomenclatureDetail
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const AddTypeDepartment: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureAdd
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const EditTypeDepartment: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureEdit
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};
export {
  TypeDepartment,
  DetailTypeDepartment,
  AddTypeDepartment,
  EditTypeDepartment,
};
