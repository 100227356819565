import GeneralRepository from "repositories/general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class NotificationRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("notification");
  }

  sendTestEmail = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/sendtestemail`;
    return await this.postAxios(url, data, cb, cbParams);
  };
  
}
