import GeneralRepository from "repositories/general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class CategoryServiceRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("categoryservice");
  }

}
