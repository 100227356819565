import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class ContactRequestRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("contactrequest");
  }
}
